// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require('jquery')
require('datatables.net-bs4')


// import the bootstrap javascript module
import "bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "@fortawesome/fontawesome-free/js/all"


import $ from 'jquery';
import select2 from 'select2';

$(function() {

  $('form:not(#custom_report) select').select2({
    placeholder: 'Select from list',
    allowClear: true,
    theme: 'bootstrap4',
    tags: true,
    multiple: false,
  });
  
  $('#custom_report select').select2({
    placeholder: 'Select from list',
    allowClear: true,
    theme: 'bootstrap4',
    tags: true,
    multiple: true,
  });

  $('select#username').select2({
    placeholder: "Select from list or type in new value",
    allowClear: true,
    theme: 'bootstrap4',
    tags: true,
  });

  var datatable_dom_settings = "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12 col-md-5'<'hint'>><'col-sm-12 col-md-7'p>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"

  // set up expenses datatable
  var expenses_table = $('#expenses-datatable')
  .dataTable({
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': $('#expenses-datatable').data('source')
    },
    "pageLength": 50,
    'pagingType': 'full_numbers',
    "order": [[3, "asc"]],
    'columns': [
      { 'data': 'checkbox', 'orderable': false },
      { 'data': 'cost_center' },
      { 'data': 'fldtype' },
      { 'data': 'flddate' },
      { 'data': 'jnumber' },
      { 'data': 'transaction_id' },
      { 'data': 'vendor' },
      { 'data': 'description' },
      { 'data': 'amount' },
      { 'data': 'fas_date' },
      { 'data': 'fund' },
      { 'data': 'assignee' },
      { 'data': 'program' },
      { 'data': 'gift' },
      { 'data': 'grant' },
      { 'data': 'activity_event' },
      { 'data': 'project' },
      { 'data': 'user_defined' },
      { 'data': 'account' },
      { 'data': 'spend_category' },
      { 'data': 'actions', 'orderable': false },
    ],
    dom: datatable_dom_settings,
  })
  .on('preXhr.dt', function ( e, settings, data ) {
    console.log( data );
  } );

  // Set up budgets datatable
  var budgets_table = $('#budgets-datatable').dataTable({
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': $('#budgets-datatable').data('source')
    },
    "pageLength": 50,
    'pagingType': 'full_numbers',
    "order": [[2, "asc"]],
    'columns': [
      { 'data': 'checkbox', 'orderable': false },
      { 'data': 'cost_center' },
      { 'data': 'flddate' },
      { 'data': 'journal_number' },
      { 'data': 'description' },
      { 'data': 'amount' },
      { 'data': 'fas_date' },
      { 'data': 'fund' },
      { 'data': 'assignee' },
      { 'data': 'program' },
      { 'data': 'gift' },
      { 'data': 'grant' },
      { 'data': 'activity_event' },
      { 'data': 'project' },
      { 'data': 'user_defined' },
      { 'data': 'account' },
      { 'data': 'spend_category' },
      { 'data': 'actions', 'orderable': false },
    ],
    dom: datatable_dom_settings,
  });

  // Set up hint help text
  $("div.hint").html('<span role="note" class="text-muted">Shift click on a column to sort by additional columns. Regular click on a column to reset the sort.</span>');
});

jQuery.fn.extend({
  get_cb_values: $('input[type=checkbox]:checked', $(this)).map(function(){
    console.log($(this))
    return this.value
  }),
});